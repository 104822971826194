
"use client"
import React, { useState, useEffect } from 'react'
import { Popover, PopoverPanel } from '@headlessui/react'

function windowIcon(i) {
  return i.icon ? (<img src={i.icon} />) :
    (<div>
      {i.name}
    </div>)
}

export default function SearchableSelect({ options, onChangeFn }) {
  const [query, setQuery] = useState('')
  const [filteredOptions, setFilteredOptions] = useState(options)
  const [isOpened, setIsOpened] = useState(false)

  useEffect(() => {
    let _ops = options

    if (query !== '') {

      _ops = options.filter((i) =>
        i.name.toLowerCase().includes(query.toLowerCase())
      )
    }

    setFilteredOptions(_ops)
  }, [options, query])

  return (<div style={{zIndex: 999}} className="flex items-center">

    <Popover className="relative isolate bg-white" style={{}}>

      <div style={{zIndex: '99'}} >
        <input
          value={query}
          placeholder='User Search'
          
          onClick={() => {
            const tmp = !isOpened

            if(!tmp) {
              setQuery(() => '')
            }
            setIsOpened(() => tmp)
          }}
          // onBlur={() => setIsOpened(false)}
          onChange={(event) => {
            const v = event.target.value
              setQuery(v)
          }}
        />

        </div>

        {!isOpened ? null : (
        <PopoverPanel
          static
          style={{ position: 'relative', top: '25px', minWidth: '250px', maxHeight: '400px', overflow: 'scroll' }}
          className="bg-white shadow-lg"
        >

          <div className="relative pl-4 md:pl-0 mx-auto max-w-7xl ">
            {filteredOptions.map((item) => (
              <div
                key={item.name}
                onClick={() => {
                  onChangeFn(item)
                }}
                style={{ justifyContent: 'center' }}
                className="pt-4 pb-4 md:pt-0 md:pb-4 group project-menu-item relative flex rounded-lg text-sm leading-6 hover:bg-gray-50 sm:flex-col sm:p-3"
              > 
                <div 
                  className="flex h-22 w-22 flex-none items-center justify-center rounded-lg group-hover:bg-white"
                >


                  {windowIcon(item)}
                </div>
                <div>
                  <div className="font-semibold text-gray-900">
                    <span className="absolute inset-0" />
                  </div>
                  <p className="mt-1 text-gray-600">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </PopoverPanel>
        )}
    </Popover>

  </div>



  )


}