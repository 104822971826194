
"use client"
// 
import localFont from "next/font/local";
import "./globals.css";

import Image from "next/image";
import logo from "./images/evans-energy-logo.png"
// import Link from "next/link";
import Head from 'next/head'
import ProfileNav from '@/app/components/profile_nav'
import isBrowser from "./helpers/is_browser";

// eslint-disable-next-line
import { useEffect, useRef, useState } from "react";
import { pageDataAtom, hasNetworkAtom, modalOpenedAtom, isLeadsPageAtom } from "@/app/state"
import { useAtom } from "jotai";
import OfflineBanner from "./components/offline_banner";
import useWindowSize from "@/app/helpers/use_window_size"
import SearchableSelect from "@/app/components/form_fields/searchable_select"
import { HighlightInit } from '@highlight-run/next/client'
import useGlobalClickHandler from "@/app/components/global_click_handler"
import FingerprintJS from '@fingerprintjs/fingerprintjs'
// import headers from "@/app/helpers/default_headers"
let fpPromise

if (isBrowser()) {
  fpPromise = FingerprintJS.load()
}


import { load } from '@fingerprintjs/botd'

// import { ErrorBoundary } from "react-error-boundary";

/*
import {
  newTracker,
  enableActivityTracking,
  trackPageView
} from '@snowplow/browser-tracker';


if (isBrowser()) {

  const proto = location.protocol
  const url = `${proto}://${location.host}`
  newTracker('default', url, {
    appId: 'ee-portal',
  });

  enableActivityTracking({
    minimumVisitLength: 30,
    heartbeatDelay: 10
  });

  trackPageView()
}
*/




// import posthog from 'posthog-js'


const blacklistOfflineBanner = ['/login', '/invest']


// import InstallPrompt from "./components/install_prompt";
// import PushNotificationManager from "./components/push_notifications_manager";

// import type { Metadata } from "next";
// export const metadata: Metadata = {
//   title: "Create Next App",
//   description: "Generated by create next app",
// };

const geistSans = localFont({
  src: "./fonts/GeistVF.woff",
  variable: "--font-geist-sans",
  weight: "100 900",
});
const geistMono = localFont({
  src: "./fonts/GeistMonoVF.woff",
  variable: "--font-geist-mono",
  weight: "100 900",
});


function LeftProjectLogo({ project }) {

  if (!project) {
    return null
  }

  return (<>
    <div className="logo-forward" style={{ alignSelf: 'center', marginLeft: '-0.8rem' }}>
      <span className="material-symbols-outlined">arrow_forward</span>
    </div>
    <div className="flex items-center">
      <img alt="project images" src={project.icon} />
    </div>
  </>)
}

function DisplayName({ name }) {
  if (!name) {
    return null
  }
  return (
    <>{name}</>
  )

}

// eslint-disable-next-line
function AdminLogout({ loggedIn }) {
  if (isBrowser() && !loggedIn) {
    return null
  }
  return (
    <>
      <li style={{ margin: '0', paddingRight: '1rem' }} >
        {/* <Link style={{ color: "white" }} href={"/login"}>Home</Link> */}
        <a style={{ color: "white" }} href={"/login"}>Home</a>
      </li>
      <li style={{ margin: 0 }}>
        <a style={{ color: "white" }} href="/logout">Log Out</a>
        {/* <Link style={{ color: "white" }} href={"/admin/logout"}>Log Out</Link> */}
      </li>
    </>
  )
}

// eslint-disable-next-line
function UserLogout({ loggedIn }) {
  return (
    <>
      <li style={{ margin: '0', paddingRight: '1rem' }} >
        <a style={{ color: "white" }} href={"/login"}>Home</a>
        {/* <Link style={{ color: "white" }} href={"/login"}>Home</Link> */}
      </li>
      <li style={{ margin: 0 }}>
        <a style={{ color: "white" }} href="/logout">Log Out</a>
        {/* <Link style={{ color: "white" }} href={"/accounts/sign_out"}>Log Out</Link> */}
      </li>
    </>)

}

// eslint-disable-next-line
function AppInstallBanner() {
  let host = ""
  if (isBrowser()) {
    host = location.hostname
  }
  if (host !== "evans-energy-b9ba239f1ce7.herokuapp.com") {
    return null
  }

  return (
    <div className="w-full bg-red-600 text-white justify-center flex font-bold p-4">
      <a href="/assets/evans_energy2.apk">Install the app</a>
    </div>
  )
}


export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {

  // eslint-disable-next-line
  const [pageData, setPageData] = useAtom(pageDataAtom)
  // eslint-disable-next-line
  const [modalOpened, setModalOpened] = useAtom(modalOpenedAtom)
  const [loggedIn, setLoggedIn] = useState(false)

  const [wellAdmin, setWellAdmin] = useState(false)
  const [wellUser, setWellUser] = useState(false)
  const [fullAdmin, setFullAdmin] = useState(false)

  const [project, setProject] = useState("")
  const [name, setName] = useState("")
  const [initComplete, setInitComplete] = useState(false)
  const [hasNetwork, setHasNetwork] = useAtom(hasNetworkAtom)
  const [highlightEnabled, setHighlightEnabled] = useState(false)
  const [menuItems, setMenuItems] = useState([])
  const [isBot, setIsBot] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [isLeadsPage] = useAtom(isLeadsPageAtom)


  function setupBotCheck() {
    return load()
      .then((botd) => botd.detect())
      .then((result) => {
        console.log(result)
        if (result.bot) {
          setIsBot(() => true)
        }
      })
      .catch((error) => console.error(error))
  }

  useEffect(() => {

  })

  useEffect(() => {

    setupBotCheck()
    if (!initComplete && isBrowser() && window && window.pageData) {
      setPageData(window.pageData)

      // trackPageView()

      if (window.pageData.highlightEnabled) {

        const suf = window.pageData.is_admin ? "_impersonator" : ''

        if (H && window.pageData.email && isBot) {
          // eslint-disable-next-line
          H.identify(window.pageData.email + suf + '_IS_BOT_')
        } else if (H && window.pageData.email) {
          // eslint-disable-next-line
          H.identify(window.pageData.email + suf)
        } else if (isBot) {
          H.identify("IS_BOT")
        }
        setHighlightEnabled(() => true)
      }
    } else if (!initComplete && isBrowser() && pageData === null) {
      // fetch('/audit.json')
      // fetch('/audit/favs.json')
      
      // fetch('/fields.json')
        // fetch('/fields/well_tickets.json')
        // fetch('/fields/well_pictures.json')
        // fetch('/fields/well_checklist.json')
        fetch('/fields/daily_totals.json')
        // fetch('/fields/well_files.json')
        // fetch('/projects/9.json')
        // fetch('/projects.json')
        // fetch('/projects/all.json')
        // fetch('/profile/contact.json')
        // fetch('/profile/addresses.json')
        // fetch('/admin2/well_percentages.json')
        // fetch('/admin2/data_check')
        .then(res => res.json())
        .then(data => {
          if (data.project) {
            data.project.icon = ""
            data.projects = data.projects.map(project => {
              project.icon = ""
              return project
            })
          }
          setPageData(data)
        })
    }

    if (!initComplete && isBrowser() && pageData !== null) {
      fpPromise.then(async (fp) => {
        fp.get().then(result => {
          const email = localStorage.getItem('email') || ''
          fetch('/ev', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              track: {
                client_time: new Date().toISOString(),
                event_type: 'page_view',
                email,
                fp: result.visitorId,
                properties: {
                  path: location.pathname,
                  query: location.search,
                  href: location.href
                }
              }
            })
          })
        })
      })
      setLoggedIn(() => pageData.loggedIn)
      setWellAdmin(() => pageData.wellAdmin)
      setWellUser(() => pageData.wellUser)
      setFullAdmin(() => pageData.fullAdmin)


      if (pageData !== null && pageData.accounts !== undefined && accounts.length === 0) {
        const i = [{ name: '', id: -1 }]
        setAccounts(() => i.concat(pageData.accounts))
      }

      if (pageData.auditUser || pageData.wellAdmin || pageData.wellUser) {
        setMenuItems([
          { name: 'Fields', href: '/fields' },
          { name: 'Logout', href: '/logout' },

        ])
      } else {
        setMenuItems([
          { name: 'Home', href: homeHref },
          { name: 'Profile', href: '/profile/contact' },
          { name: 'Logout', href: '/logout' },

        ])
      }

      if (pageData.loggedIn) {
        setName(() => pageData.name)
        setProject(() => pageData.project)
      }

      setInitComplete(() => true)
      if (pageData === null) {
        setPageData(window.pageData)
      }
    }

  }, [loggedIn, project, name, pageData, wellAdmin, wellUser, fullAdmin, hasNetwork, setHasNetwork, initComplete, setPageData])


  // eslint-disable-next-line
  async function checkSWVersion() {
    const version = await serwist.messageSW({ type: 'GET_VERSION' })
    console.log("VERSION ", version)
  }

  function isAdmin() {
    return wellAdmin || wellUser || fullAdmin
  }

  function setAccount(account) {
    if (account.id === -1) {
      return
    }

    location.href = `/impersonate_user/${account.id}`
  }

  const homeHref = isAdmin() ? '/login' : '/'

  const [width] = useWindowSize()
  useGlobalClickHandler(fpPromise)

  const [isMobile, setIsMObile] = useState(false)

  function userSelectorOpened() {
    setModalOpened(true)
  }

  useEffect(() => {
    setIsMObile(width < 640)
  }, [width])

  useEffect(() => {
    if (isBrowser()) {
      const heartbeatInt = setInterval(() => {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 2000);
        fetch(`/api/ping`, { signal: controller.signal }).then(res => {
          if (res.status === 200 && !hasNetwork) {
            setHasNetwork(true)
          } else if (res.status !== 200 && hasNetwork) {
            setHasNetwork(false)
          }
        }).catch(() => {
          if (hasNetwork) {
            setHasNetwork(false)
          }
        }).finally(() => {
          clearTimeout(timeoutId);
        })
      }, 4000)

      return () => clearInterval(heartbeatInt)
    }
  }, [loggedIn, project, name, pageData, wellAdmin, wellUser, fullAdmin, hasNetwork, setHasNetwork, initComplete, setPageData])


  return (
    <>
      {highlightEnabled ? (
        <HighlightInit
          projectId={'qe981lod'}
          serviceName="ee-portal"
          tracingOrigins
          networkRecording={{
            enabled: true,
            recordHeadersAndBody: true,
            urlBlocklist: ['/api/ping', '/_next', '/accounts/sign_in', '/accounts/password'],
          }}
        />) : null}
      <html lang="en">
        <Head>
          <title>Evans Energy II - Joint Venture Oil & Gas</title>
          <link rel="manifest" href="/manifest.json" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
        </Head>
        <body
          className={`${geistSans.variable} ${geistMono.variable} antialiased`}
        >

          {/* <ErrorBoundary fallback={<p>⚠️Something went wrong</p>}> */}


          {/* <PushNotificationManager /> */}
          {isBrowser() && (isLeadsPage || blacklistOfflineBanner.includes(location.pathname)) ? null :
            <OfflineBanner />
          }
          {/* <AppInstallBanner /> */}

          {isLeadsPage ? null : (
            <nav className="header border-gray-200">

              <div style={{ height: '100%' }}>
                <div className="flex" style={{ height: '100%' }}>
                  <div className="flex" style={{ maxWidth: '355px', height: '100%', padding: '0 1rem 0 1rem' }}>
                    <a href="/projects" className="flex items-center space-x-3 rtl:space-x-reverse no-underline">
                      <Image alt="Evans Energy Logo" src={logo} style={{ width: '175px' }} />
                    </a>
                    <LeftProjectLogo project={project} />

                    {modalOpened || !loggedIn || !isMobile ? null : (
                      <div className="absolute" style={{ right: 0 }}>


                        <ProfileNav menuItems={menuItems} />
                      </div>
                    )}

                  </div>

                  <div className="flex header_account_name">

                    <div className="hidden md:block" style={{ margin: '0 auto', alignContent: 'center' }} >
                      <h1 className="project_name" >
                        <DisplayName name={name} />
                      </h1>
                    </div>

                    {modalOpened || !loggedIn ? null : (
                      <ProfileNav menuItems={menuItems} />
                    )}
                  </div>
                </div>
              </div>
            </nav >
          )}

          {accounts.length === 0 ? null : (

            <div className="max-w-screen-xl p-0 md:p-4 flex flex-col md:flex-row mx-auto">
              <SearchableSelect label={''} selectedId={0} options={accounts} factor={20} minWidth={`300px`} onOpened={userSelectorOpened} onChangeFn={setAccount} />
            </div>
          )}

          {/* <InstallPrompt /> */}
          {children}
          {/* </ErrorBoundary> */}
        </body >
      </html >
    </>
  );
}

