import { useLayoutEffect } from "react";

export default function useGlobalClickHandler(fpPromise) {

  const handleClick = async (ev) => {
    const email = localStorage.getItem('email') || ''
    const fp = await fpPromise
    const result = await fp.get()
    
    fetch('/ev', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        track: {
          client_time: new Date().toISOString(),
          event_type: 'click',
          email,
          fp: result.visitorId,
          properties: {
            target: ev.target.outerHTML, 
          }    
        }
      })
    })
  }

  useLayoutEffect(() => {
    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
  }, []);
}