import { hasNetworkAtom } from "@/app/state";
import { useAtom } from "jotai";

export default function OfflineBanner() {
  const [hasNetwork] = useAtom(hasNetworkAtom)

  if(hasNetwork) {
    return null
  }

  return (
    <div className="w-full bg-red-600 text-white justify-center flex font-bold p-4">  
      Warning, no network detected. Either you are offline or we are deploying a new build.
    </div>
  )
  
}